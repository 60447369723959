<template>
  <b-card>
    <!-- Table Top -->
    <b-row>
      <b-col md="6" />
      <b-col
        md="6"
        class="list-top-buttons"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            v-b-modal.add-new-item-sidebar
            variant="primary"
            :to="{ name: 'apps-resourcesCategories' }"
          >
            <span class="text-nowrap">Category List</span>
          </b-button>
          <b-button
            v-b-toggle.add-knowledgebase-right
            variant="primary"
            style="margin-left: 10px"
            :to="{ name: 'apps-add-knowledgebase' }"
          >
            <span class="text-nowrap">Add Knowledgebase</span>
          </b-button>

          <b-button
            class="ml-1"
            variant="outline-none"
            :to="{ name: 'apps-knowledgebases-card' }"
          >
            <feather-icon
              icon="GridIcon"
              size="20"
            />
          </b-button>

          <b-button variant="outline-none">
            <feather-icon
              icon="MenuIcon"
              size="20"
            />
          </b-button>
        </div>
      </b-col>
    </b-row>

    <div>
      <AomLanguages>
        <b-row class="mt-2 match-height">
          <b-col md="10">
            <b-row class="mt-1">
              <b-col md="5">
                <b-row>
                  <!-- title -->
                  <b-col md="6">
                    <validation-provider
                      v-slot="{ errors }"
                      ref="name"
                      :rules="{ required: true }"
                      name="Title"
                    >
                      <b-form-group
                        label="Title"
                        label-for="title"
                      >
                        <b-form-input
                          id="title"
                          v-model="kbTitle"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Title"
                        />
                        <span class="text-danger">{{ errors[0] }}</span>
                      </b-form-group>
                    </validation-provider>
                  </b-col>

                  <!-- Program Type -->
                  <b-col md="6">
                    <validation-provider
                      v-slot="validationContext"
                      ref="resourceType"
                      rules="required"
                      name="Resource Type"
                    >
                      <b-form-group
                        label="Type"
                        label-for="kb-type"
                      >
                        <v-select
                          id="kb-type"
                          v-model="selectedType"
                          :dir="dir"
                          label="text"
                          placeholder="Select the Resource Type"
                          :options="resourceTypesDisplay"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>
              <!--  -->

              <b-col md="7">
                <b-row>
                  <!-- Duration 1 Show -->
                  <b-col
                    md="12"
                    class=""
                  >
                    <label
                      for="Duration"
                      class="ml-1"
                    >Show from</label>

                    <div class="ml-1">
                      <b-row>
                        <b-col
                          md="3"
                          class=""
                        >
                          <validation-provider
                            v-slot="validationContext"
                            ref="matchDuration"
                            rules="required"
                            name="Duration"
                          >
                            <b-form-group label-for="match">
                              <v-select
                                id="match"
                                v-model="selectedMatch"
                                label="text"
                                placeholder="Match"
                                :options="matchOptionsDisplay"
                                :state="getValidationState(validationContext)"
                                class="medium-select-list"
                              />
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                              >
                                {{
                                  validationContext.errors[0]
                                }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!--  -->

                        <b-col md="2">
                          <validation-provider
                            v-slot="validationContext"
                            ref="duration"
                            rules="required"
                            name="duration"
                          >
                            <b-form-group label-for="duration">
                              <v-select
                                id="selectedDuration"
                                v-model="selectedDuration"
                                :options="matchDurationValuesDisplay"
                                label="text"
                                placeholder="Duration"
                                :state="getValidationState(validationContext)"
                                class="small-select-list"
                              />
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                              >
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!--  -->

                        <b-col md="3">
                          <validation-provider
                            v-slot="validationContext"
                            ref="durationType"
                            rules="required"
                            name="Type"
                          >
                            <b-form-group label-for="durationTypes">
                              <v-select
                                id="durationTypes"
                                v-model="selectedDurationType"
                                :options="durationPeriodDisplay"
                                label="text"
                                placeholder="Period"
                                :state="getValidationState(validationContext)"
                                class="ml-1"
                              />
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                              >
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                        <!--  -->

                        <b-col md="3">
                          <validation-provider
                            v-slot="validationContext"
                            ref="momentType"
                            rules="required"
                            name="Moment"
                          >
                            <b-form-group label-for="momentType">
                              <v-select
                                id="momentType"
                                v-model="selectedMomentType"
                                :options="momentTypesDisplay"
                                label="text"
                                placeholder="Moment"
                                :state="getValidationState(validationContext)"
                              />
                              <b-form-invalid-feedback
                                :state="getValidationState(validationContext)"
                              >
                                {{ validationContext.errors[0] }}
                              </b-form-invalid-feedback>
                            </b-form-group>
                          </validation-provider>
                        </b-col>
                      </b-row>
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <!-- col MD 7 -->
            </b-row>

            <b-row class="mt-3">
              <b-col md="5">
                <b-row>
                  <!-- title -->
                  <b-col md="6">
                    <user-roles-select
                      v-model="selectedUserRole"
                    />
                  </b-col>

                  <b-col md="6">
                    <validation-provider
                      v-slot="validationContext"
                      ref="Category"
                      rules="required"
                      name="Category"
                    >
                      <b-form-group
                        label="Category"
                        label-for="category"
                      >
                        <v-select
                          v-model="selectedCategory"
                          :dir="dir"
                          multiple
                          label="text"
                          placeholder="Select the Category"
                          :options="categoriesDisplay"
                          :state="getValidationState(validationContext)"
                        />
                        <b-form-invalid-feedback
                          :state="getValidationState(validationContext)"
                        >
                          {{ validationContext.errors[0] }}
                        </b-form-invalid-feedback>
                      </b-form-group>
                    </validation-provider>
                  </b-col>
                </b-row>
              </b-col>

              <!-- Duration 2 -->
              <b-col md="7">
                <label
                  for="hideDuration"
                  class="ml-1"
                >Hide from</label>
                <div class="ml-1">
                  <b-row>
                    <!-- Duration 1 Show -->
                    <b-col
                      md="3"
                      class=""
                    >
                      <validation-provider
                        v-slot="validationContext"
                        ref="hide-duration"
                        rules="required"
                        name="Duration"
                      >
                        <b-form-group label-for="hide-match">
                          <v-select
                            id="hideMatch"
                            v-model="hideSelectedMatch"
                            placeholder="Match"
                            :options="matchOptionsDisplay"
                            label="text"
                            :state="getValidationState(validationContext)"
                            class="medium-select-list"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!--  -->

                    <b-col md="2">
                      <validation-provider
                        v-slot="validationContext"
                        ref="hide-duration"
                        rules="required"
                        name="Duration"
                      >
                        <b-form-group label-for="hide-duration">
                          <v-select
                            id="hideSelectedDuration"
                            v-model="hideSelectedDuration"
                            :options="matchDurationValuesDisplay"
                            label="text"
                            placeholder="Duration"
                            :state="getValidationState(validationContext)"
                            class="small-select-list"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!--  -->

                    <b-col md="3">
                      <validation-provider
                        v-slot="validationContext"
                        ref="hide-durationType"
                        rules="required"
                        name="Type"
                      >
                        <b-form-group label-for="durationTypes">
                          <v-select
                            id="hideDurationTypes"
                            v-model="hideSelectedDurationType"
                            :options="durationPeriodDisplay"
                            label="text"
                            placeholder="Period"
                            :state="getValidationState(validationContext)"
                            class="ml-1"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!--  -->

                    <b-col md="3">
                      <validation-provider
                        v-slot="validationContext"
                        ref="hide-momentType"
                        rules="required"
                        name="Moment"
                      >
                        <b-form-group label-for="hideMomentType">
                          <v-select
                            id="hideMomentType"
                            v-model="hideSelectedMomentType"
                            :options="momentTypesDisplay"
                            placeholder="Moment"
                            label="text"
                            :state="getValidationState(validationContext)"
                          />
                          <b-form-invalid-feedback
                            :state="getValidationState(validationContext)"
                          >
                            {{ validationContext.errors[0] }}
                          </b-form-invalid-feedback>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </div>
              </b-col>
            </b-row>
          </b-col>

          <b-col md="2">
            <div class="border rounded p-2">
              <b-media
                no-body
                vertical-align="center"
              >
                <b-media-aside>
                  <b-img
                    ref="refPreviewEl"
                    :src="require('@/assets/images/profile/post-media/25.jpg')"
                    height="110"
                    width="170"
                    class="rounded mr-2 mb-1 mb-md-0"
                  />
                </b-media-aside>

                <b-media-body>
                  <b-form-file
                    ref="refInputEl"
                    v-model="blogFile"
                    accept=".jpg, .png, .gif"
                    placeholder="Add Image"
                    class="mt-2 imageReader"
                    @input="inputImageRenderer"
                  />
                </b-media-body>
              </b-media>
            </div>
          </b-col>
        </b-row>

        <!-- Description -->
        <b-row class="theme-list-title">
          <b-col md="12">
            <h4>Topic</h4>
            <AoMTextEditor :height="300" />
          </b-col>
        </b-row>

        <!-- submit and reset -->

        <b-row class="mt-5">
          <b-col cols="12">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="reset"
              variant="outline-secondary"
              class="mr-1"
            >
              Cancel
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="submit"
              variant="primary"
            >
              Submit
            </b-button>
          </b-col>
        </b-row>
      </AomLanguages>
    </div>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  VBToggle,
  BFormInvalidFeedback,
} from "bootstrap-vue";

import AoMTextEditor from "@/@aom-core/AoMTextEditor.vue";
import AomLanguages from "@aom-core/AomLanguages";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import UserRolesSelect from '@/@aom-core/UserRolesSelect.vue';
import { ref } from "@vue/composition-api";
import { ValidationProvider } from "vee-validate";
import { required } from "@validations";
import { getValidationState } from "@/libs/utils";

import {
  matchDurationValuesDisplay,
  matchOptionsDisplay,
  momentTypesDisplay,
} from "@/models/enums/ePrograms";
import { resourceTypesDisplay } from "@/models/eResources";
import { categoriesDisplay } from "@/models/eCategories";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    BFormFile,
    vSelect,
    BImg,
    BMedia,
    BMediaAside,
    BMediaBody,
    AoMTextEditor,
    AomLanguages,
    UserRolesSelect,
    ValidationProvider,
    BFormInvalidFeedback,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      required,
      kbTitle: null,
      dir: "ltr",
      blogFile: null,
      dateTime: null,
      //TODO: Is pending create vars for each language
      selectedMatch: null,
      hideSelectedMatch: null,

      selectedType: [{ text: "Video" }],

      selectedCategory: [],

      selectedUserRole: [],

      selectedMomentType: null,

      hideSelectedMomentType: null,

      selectedDurationType: null,

      hideSelectedDurationType: null,

      selectedDuration: null,

      hideSelectedDuration: null,
    };
  },
  watch: {
    selectedType() {
      this.$emit("update", this.selectedType);
    },

    selectedMatch() {
      this.$emit("update", this.selectedMatch);
    },
  },
  methods: {
    formatNames(files) {
      if (files.length === 1) {
        return files[0].name;
      }
      return `${files.length} files selected`;
    },
  },
  setup() {
    const refInputEl = ref(null);
    const refPreviewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(
      refInputEl,
      base64 => {
        refPreviewEl.value.src = base64;
      }
    );

    return {
      getValidationState,
      refInputEl,
      refPreviewEl,
      inputImageRenderer,
      matchDurationValuesDisplay,
      matchOptionsDisplay,
      momentTypesDisplay,
      resourceTypesDisplay,
      categoriesDisplay,
    };
  },
};
</script>

<style lang="scss">
.category > div div div div span {
  background-color: orange;
}

option {
  min-height: 2em !important;
  font-size: 17px !important;
  padding: 20px 10px;
  top: 6px;
}
</style>

<style lang="scss" scoped>
.media {
  display: contents !important;
  align-items: center !important;
}

.showHidenSelect {
  width: 150px !important;
}
.shortSelect {
  width: 100px !important;
}

.small-select-list {
  width: 105px;
}

.medium-select-list {
  min-width: 165px;
}
</style>
