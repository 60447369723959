export const categories = Object.freeze({
    CATEGORY_1: 1,
    CATEGORY_2: 2,
    CATEGORY_3: 3,
    CATEGORY_4: 4,
    CATEGORY_5: 5
  });
export const categoriesDisplay = [
    { value: [categories.CATEGORY_1], text: 'Category 1'},
    { value: [categories.CATEGORY_2], text: 'Category 2'},
    { value: [categories.CATEGORY_3], text:  'Category 3'},
    { value: [categories.CATEGORY_4], text:  'Category 4'},
    { value: [categories.CATEGORY_5], text:  'Category 5'}
];
