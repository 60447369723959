export const resourceTypes = Object.freeze({
  FILE: 1,
  URL: 2,
  VIDEO: 3,
  BOOK: 4
});
export const resourceTypesDisplay = [
  { value: [resourceTypes.FILE], text: 'File'},
  { value: [resourceTypes.URL], text: 'Url'},
  { value: [resourceTypes.VIDEO], text:  'Video'},
  { value: [resourceTypes.BOOK], text:  'Book'}
];

export const fileOptions = Object.freeze({
  A: 1,
  B: 2,
  C: 3
});
export const fileOptionsDisplay = [
  { value: [fileOptions.A], text: 'File 1'},
  { value: [fileOptions.B], text: 'File 2'},
  { value: [fileOptions.C], text: 'File 3'}
];

export const urlOptions = Object.freeze({
  A: 1,
  B: 2,
  C: 3
});
export const urlOptionsDisplay = [
  { value: [urlOptions.A], text: 'URL 1'},
  { value: [urlOptions.B], text: 'URL 2'},
  { value: [urlOptions.C], text: 'URL 3'}
];

export const otherOptions = Object.freeze({
  A: 1,
  B: 2,
  C: 3,
  D: 4
});
export const otherOptionsDisplay = [
  { value: [otherOptions.A], text: 'URL 1'},
  { value: [otherOptions.B], text: 'URL 2'},
  { value: [otherOptions.C], text: 'BOOK 1'},
  { value: [otherOptions.C], text: 'BOOK 2'}
];


export const cardResource = Object.freeze({
  RESOURCE_1: 1
});
export const cardResourceDisplay= [
  {
    id: [cardResource.RESOURCE_1],
    title: "How to Mentor",
    description:
      "Match multiple mentees/peers together in a group, based on specific criteria that makes it an effective group. Add a mentor, or two, and even choose a chair for the group",
    type: "URL",
    access: "User Role",
    availability: "Post Match",
    categories: [
      { name: "Category 3", color: "#6c2a82" },
      { name: "Category 2", color: "#741313" },
    ],
    lastModified: "04/03/2022",
    image: require("@/assets/images/slider/12.jpg"),
    url: "https://pv1.aomstg01.com/img/logo.png",
    helpLink: "https://artofmentoring.net/why-mentoring/",
  }
];

export const resources = Object.freeze({
  RESOURCE_1: 1,
  RESOURCE_2: 2,
  RESOURCE_3: 3,
  RESOURCE_4: 4,
  RESOURCE_5: 5,
  RESOURCE_6: 6,
  RESOURCE_7: 7,
  RESOURCE_8: 8,
  RESOURCE_9: 9,
  RESOURCE_10: 10
});
export const resourcesDisplay = [
  
  {
    id: resources.RESOURCE_1,
    title: 'Getting Started',
    description: 'Creating mentoring relationships that work to satisfy human connection and produce unexpectedly pleasing results for people and their organisations.',
    type: 'File',
    access: 'Mentor',
    availability: 'Post Match',
    categories: [ {name:'Category 1', color: '#077c0d'}, {name:'Category 2', color: '#7f7f29'}, ],
    lastModified: '09/23/2021',
    image: require('@/assets/images/slider/12.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_2,
    title: 'Training 1',
    description: 'Match multiple mentees/peers together in a group, based on specific criteria that makes it an effective group. Add a mentor, or two, and even choose a chair for the group',
    type: 'URL',
    access: 'Mentee',
    availability: 'Post Match',
    categories: [ {name:'Category 3', color: '#6c2a82'}, {name:'Category 2', color: '#741313'}, ],
    lastModified: '03/13/2020',
    image: require('@/assets/images/slider/11.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_3,
    title: 'Learning 1',
    description: 'Creating mentoring relationships that work to satisfy human connection and produce unexpectedly pleasing results for people and their organisations.',
    type: 'File',
    access: 'Champion',
    availability: 'Post Match',
    categories: [ {name:'Category 3', color: '#6c2a82'}, {name:'Category 4', color: 'brown'}, ],
    lastModified: '11/11/2019',
    image: require('@/assets/images/slider/22.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_4,
    title: 'Help 2',
    description: 'Match multiple mentees/peers together in a group, based on specific criteria that makes it an effective group. Add a mentor, or two, and even choose a chair for the group',
    type: 'URL',
    access: 'User Role', 
    availability: 'Post Match',
    categories: [ {name:'Category 3', color: '#6c2a82'}, {name:'Category 2', color: '#741313'}, ],
    lastModified: '04/03/2022',
    image: require('@/assets/images/slider/18.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_5,
    title: 'Training 7',
    description: 'Instil ethical decision making at all levels and in particular at the leadership levels to then ripple outward into the organisation',
    type: 'File',
    access: 'Mentor, Mentee',
    availability: 'Post Match',
    categories: [ {name:'Category 3', color: '#6c2a82'}, {name:'Category 4', color: 'brown'}, ],
    lastModified: '07/19/2018',
    image: require('@/assets/images/slider/23.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png', 
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_6,
    title: 'Training initial',
    description: 'Enable the mentoring software to allow for mentors and mentees to access the program at any time and match themselves',
    type: 'URL',
    access: 'Mentor',
    availability: 'Post Match',
    categories: [ {name:'Category 3', color: '#6c2a82'}, {name:'Category 2', color: '#741313'}, ],
    lastModified: '06/17/2021',
    image: require('@/assets/images/slider/13.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_7,
    title: 'Help 7 ...',
    description: 'Match peers to create cross collaboration and support mechanisms, especially for those in senior positions',
    type: 'File',
    access: 'Mentor, Mentee',
    availability: 'Post Match',
    categories: [ {name:'Category 6', color: 'green'}, {name:'Category 5', color: '#741313'}, ],
    lastModified: '02/08/2019',
    image: require('@/assets/images/slider/20.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_8,
    title: 'Traininf Based Systems',
    description: 'Create a diverse workforce whether that’s for more gender parity, multicultural participation, indigenous programs or equal opportunities across all staff',
    type: 'URL',
    access: 'Champion',
    availability: 'Post Match',
    categories: [ {name:'Category 7', color: 'blue'}, {name:'Category 8', color: 'green'}, ],
    lastModified: '04/18/2018',
    image: require('@/assets/images/slider/21.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_9,
    title: 'Team Training',
    description: 'Support your middle management who may not all be ready to step into senior leadership, therefore increasing their satisfaction. It’s a great way to create succession planning and to retain those who don’t yet step up a level',
    type: 'File',
    access: 'User Role',
    availability: 'Post Match',
    lastModified: '08/13/2018',
    image: require('@/assets/images/slider/16.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
  {
    id: resources.RESOURCE_10,
    title: 'Management Training',
    description: 'This is a wider card with supporting text below as a natural lead-in to additional content. This content is a little bit longer.',
    type: 'URL',
    access: 'Mentee',
    availability: 'Post Match',
    lastModified: '03/27/2019',
    image: require('@/assets/images/slider/17.jpg'),
    url: 'https://pv1.aomstg01.com/img/logo.png',
    helpLink: 'https://artofmentoring.net/why-mentoring/',
  },
];


export const resourcesCards = Object.freeze({
  RESOURCE_1: 1,
  RESOURCE_2: 2,
  RESOURCE_3: 3,
  RESOURCE_4: 4,
  RESOURCE_5: 5,
  RESOURCE_6: 6,
  RESOURCE_7: 7,
  RESOURCE_8: 8,
  RESOURCE_9: 9,
  RESOURCE_10: 10
});
